/*
 * Copyright (C) 2016 Bilibili. All Rights Reserved.
 *
 * @author zheng qian <xqq@xqq.im>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Log from '../utils/logger.js';
import {BaseLoader, LoaderStatus, LoaderErrors} from './loader.js';
import {RuntimeException} from '../utils/exception.js';

// For MPEG-TS/FLV over WebSocket live stream
class WebSocketLoader extends BaseLoader {

    static isSupported() {
        try {
            return (typeof self.WebSocket !== 'undefined');
        } catch (e) {
            return false;
        }
    }

    constructor() {
        super('websocket-loader');
        this.TAG = 'WebSocketLoader';

        this._needStash = true;

        this._ws = null;
        this._requestAbort = false;
        this._receivedLength = 0;
        this._mediaChunksMap = new Map();
        this._mediaChunksStatus = new Map();
        this._currentChunckIndex = 0;
        this._firstChunkReceived = true;
        this._keyVideoReceived = false;
        this._bufferInterval = null;
        this._minBufferLatency = 0;
        this._maxBufferLatency = 20;
        this._currentBufferLatency = 0;
        this._skipCounter=0;
        this._skipChunks = new Map();
        this._notFindedCount = 0;
        this._noDataCounter = 0;

    }

    destroy() {
        if(this._bufferInterval!=null){
            clearInterval(this._bufferInterval);
        }
        if (this._ws) {
            this.abort();
        }
        super.destroy();
    }

    open(dataSource) {
        try {
            let ws = this._ws = new self.WebSocket(dataSource.url);
            ws.binaryType = 'arraybuffer';
            ws.onopen = this._onWebSocketOpen.bind(this);
            ws.onclose = this._onWebSocketClose.bind(this);
            ws.onmessage = this._onWebSocketMessage.bind(this);
            ws.onerror = this._onWebSocketError.bind(this);

            this._status = LoaderStatus.kConnecting;
        } catch (e) {
            this._status = LoaderStatus.kError;

            let info = {code: e.code, msg: e.message};

            if (this._onError) {
                this._onError(LoaderErrors.EXCEPTION, info);
            } else {
                throw new RuntimeException(info.msg);
            }
        }
    }

    abort() {
        let ws = this._ws;
        if (ws && (ws.readyState === 0 || ws.readyState === 1)) {  // CONNECTING || OPEN
            this._requestAbort = true;
            ws.close();
        }

        this._ws = null;
        this._status = LoaderStatus.kComplete;
    }

    _onWebSocketOpen(e) {
        this._status = LoaderStatus.kBuffering;
    }

    _onWebSocketClose(e) {
        if (this._requestAbort === true) {
            this._requestAbort = false;
            return;
        }

        this._status = LoaderStatus.kComplete;

        if (this._onComplete) {
            this._onComplete(0, this._receivedLength - 1);
        }
    }

    async _playbackManager(initIndex){

        var secuenceInit = initIndex;

        var bufferLoop = 0;
        this._currentBufferLatency = this._minBufferLatency;
        var executeLoopCount = 0;

        var baseTimeStamp = Date.now();
        

        this._bufferInterval = setInterval(()=>{

            var currentTimeStamp = Date.now();
            var targetTimeStamp = baseTimeStamp + (executeLoopCount*100);

            if(currentTimeStamp>=targetTimeStamp){

                var timeStapRate = (currentTimeStamp - baseTimeStamp)/100;
                timeStapRate = timeStapRate + "/" + executeLoopCount;

                if(bufferLoop>=this._minBufferLatency){

                

                    if(this._mediaChunksMap.has(secuenceInit)){
    
                        //console.log("Execute Playback for: "+secuenceInit);
                        this._dispatchArrayBuffer(this._mediaChunksMap.get(secuenceInit));
                        this._mediaChunksMap.delete(secuenceInit);
                        //document.receiveReport(secuenceInit,"OK");
    
                        /*
                        this.dispatchEvent(new CustomEvent("w6LoaderData", {
                            detail: { sequenceID: secuenceInit, status: "OK" }
                        }));
                        */
    
                        //var eventOK = new CustomEvent('w6LoaderData', { sequenceID: secuenceInit, status: "OK" });
    
                        secuenceInit++;
                        this._notFindedCount = 0;
    
                        if(this._currentBufferLatency>this._minBufferLatency){
    
                            if(this._mediaChunksMap.has(secuenceInit)){
    
                                this._dispatchArrayBuffer(this._mediaChunksMap.get(secuenceInit));
                                this._mediaChunksMap.delete(secuenceInit);
    
                                this._currentBufferLatency--;
    
                                console.log(timeStapRate + " - SHIFT LATENCY 1: "+secuenceInit+", BUFFER: "+this._currentBufferLatency);
                                secuenceInit++;

                                if(this._currentBufferLatency>this._minBufferLatency){

                                    if(this._mediaChunksMap.has(secuenceInit)){

                                        this._dispatchArrayBuffer(this._mediaChunksMap.get(secuenceInit));
                                        this._mediaChunksMap.delete(secuenceInit);
    
                                        this._currentBufferLatency--;
    
                                        console.log(timeStapRate + " - SHIFT LATENCY 2: "+secuenceInit+", BUFFER: "+this._currentBufferLatency);
                                        secuenceInit++;

                                    }

                                }
    
                            }
    
                        }
    
                    }else{
    
                        /*
                        if(this._skipChunks.has(secuenceInit)){
    
                            console.log(timeStapRate + " - SKIPING SEQ: "+secuenceInit);
                            secuenceInit++;
                            this._skipChunks.delete(secuenceInit);
    
                        }else{
                        */
                            
    
                        
    
                            if(this._currentBufferLatency>=this._maxBufferLatency){
    
                                console.log(this._noDataCounter + " - NO DATA FINDED FOR: "+secuenceInit+", BUFFER: "+this._currentBufferLatency);
                                secuenceInit++;

                                this._noDataCounter++;

                                /*
                                this._notFindedCount++;
    
                                if(this._notFindedCount==10){
    
                                    if(this._skipCounter>0){

                                    }else{
                                        this._skipCounter = 20;
                                    }
                                    
                                    this._notFindedCount = 0;
    
                                }
                                */
    
                            }else{
    
                                this._currentBufferLatency++;
    
                            }
    
                        //}
    
                        
    
                        //document.receiveReport(secuenceInit,"NODATA");
                        /*
                        this.dispatchEvent(new CustomEvent("w6LoaderData", {
                            detail: { sequenceID: secuenceInit, status: "FAILED" }
                        }));
                        */
    
                        //var eventERROR = new CustomEvent('w6LoaderData', { sequenceID: secuenceInit, status: "FAILED" });
    
                    }
    
                    
    
                }else{
    
                    console.log(timeStapRate + " - Making buffer, loop id: "+bufferLoop);
                    bufferLoop++;
    
                }

                executeLoopCount++;

            }


            

            
            
            
            
        },10);

    }

    async _downloadFile(chunkSecond,chunkSubsegment) {

        var chunkUrlToDownload = "https://demo.wom6.com:9595/stream/demo/LIVE/720/stream"+chunkSecond+"_"+chunkSubsegment+".ts";
        var chunkLabel = chunkSecond+"_"+chunkSubsegment;
    
        const response = await fetch(chunkUrlToDownload);
        const reader = response.body.getReader();
    
        var chunkVideoData = [];
        var chunkVideoData_counter = 0;
        var chunkVideoData_size = 0;
        var chunkVideoData_parts = 0;
    
        var totalSizeChunkData = 0;
        var totalSegmentsReady = true;

        var secuenceIndexToSet = (chunkSecond * 10) + chunkSubsegment;

        
    
          let dataChunk;
          do {
            dataChunk = await reader.read();
            if (!dataChunk.done){
                //console.log("PART "+chunkVideoData_parts + ":" + url+ " -> CHUNK DATA: "+dataChunk.value.length);
                chunkVideoData[chunkVideoData_counter] = dataChunk.value;
                chunkVideoData_size = chunkVideoData_size + dataChunk.value.length;
                chunkVideoData_parts++;
                chunkVideoData_counter++;
            }
            //if (!dataChunk.done) this.buffer.add(dataChunk.value);
          } while (!dataChunk.done);
    
        if(chunkVideoData_size>0){
    
            let allDataChunkArray = new Uint8Array(chunkVideoData_size);
    
            var chunkNextIndex = 0;
            for(var i=0; i<chunkVideoData_parts; i++){
    
                allDataChunkArray.set(chunkVideoData[i],chunkNextIndex);
                chunkNextIndex = chunkNextIndex + chunkVideoData[i].length;
    
            }
    
            /*
            if(!this._mediaChunksMap.has(chunkSecond)){
                this._mediaChunksMap.set(chunkSecond,new Map());
                //this._mediaChunksStatus.set(chunkSecond,new Map());
            }
    
            if(!this._mediaChunksMap.get(chunkSecond).has(chunkSubsegment)){
                this._mediaChunksMap.get(chunkSecond).set(chunkSubsegment,allDataChunkArray);
                //this._mediaChunksStatus.get(chunkSecond).set(chunkSubsegment,"Downloaded");
            }else{
                this._mediaChunksMap.get(chunkSecond).delete(chunkSubsegment);
                //this._mediaChunksStatus.get(chunkSecond).delete(chunkSubsegment);
                this._mediaChunksMap.get(chunkSecond).set(chunkSubsegment,allDataChunkArray);
                //this._mediaChunksStatus.get(chunkSecond).set(chunkSubsegment,"Downloaded");
            }
            */
            
            if(!this._mediaChunksMap.has(secuenceIndexToSet)){

                this._mediaChunksMap.set(secuenceIndexToSet,allDataChunkArray);

            }else{

                this._mediaChunksMap.delete(secuenceIndexToSet);
                this._mediaChunksMap.set(secuenceIndexToSet,allDataChunkArray);

            }
    
            //console.log(chunkLabel + "(" + secuenceIndexToSet + ") -> DATA SAVED: "+allDataChunkArray.length);

            /*
            if(this._currentChunckIndex==secuenceIndexToSet){

                this._dispatchArrayBuffer(allDataChunkArray);
                this._mediaChunksMap.delete(secuenceIndexToSet);
                console.log("APPENDED DATA: "+this._currentChunckIndex);
                this._currentChunckIndex++;

            }else if(this._currentChunckIndex<secuenceIndexToSet){

                
                for(var i=this._currentChunckIndex;i<=secuenceIndexToSet;i++){

                    if(this._mediaChunksMap.has(i)){

                        this._dispatchArrayBuffer(this._mediaChunksMap.get(i));
                        this._mediaChunksMap.delete(i);
                        console.log("APPENDED DATA: "+this._currentChunckIndex);
                        this._currentChunckIndex++;

                    }else{

                        break;

                    }

                }

            }
            */

            //sourceBuffer.appendBuffer(mediaChunksMap.get(chunkSecond).get(chunkSubsegment));
    
            /*
            if (videoElement.paused) {
                // start playing after first chunk is appended
                videoElement.play();
            }
            */

            //this._dispatchArrayBuffer(allDataChunkArray);
    
            /*
            var lowerIndex = 0;
            var higherIndex = 5;
            if(chunkSubsegment>4){
                lowerIndex = 5;
                higherIndex = 10;
            }
            
            for(var i=lowerIndex; i<higherIndex; i++){
                if(this._mediaChunksMap.has(chunkSecond)){
                    if(this._mediaChunksMap.get(chunkSecond).has(i)){
                        totalSizeChunkData = totalSizeChunkData + this._mediaChunksMap.get(chunkSecond).get(i).length;
                    }else{
                        totalSegmentsReady = false;
                    }
                }else{
                    totalSegmentsReady = false;
                }
            }
            
    
            
            if(totalSegmentsReady){
    
                
                //sourceBuffer.appendBuffer(vidBuff);
                for(var i=lowerIndex; i<higherIndex; i++){
                    if(this._mediaChunksMap.has(chunkSecond)){
                        if(this._mediaChunksMap.get(chunkSecond).has(i)){
                            //sourceBuffer.appendBuffer(mediaChunksMap.get(chunkSecond).get(i));
                            //sourceBuffer.appendBuffer(new Uint8Array(mediaChunksMap.get(chunkSecond).get(i)));

                            this._dispatchArrayBuffer(this._mediaChunksMap.get(chunkSecond).get(i));

                        }
                    }
                }
                
    
                //var cBandwidth = ((totalSizeChunkData/1000)/1000)*8;
                //console.log(chunkLabel + " -> BANDWIDTH: "+cBandwidth+"Mbps");
    
            }
            */
            
    
        }
    
        /*
        const response = await fetch(url);
          const reader = response.body.getReader();
          do {
            const { done, dataChunk } = await reader.read();
            // Store the `dataChunk` to IndexedDB.
          } while (!done);
        */
    }

    

    _onWebSocketMessage(e) {

        //Change to W6 System
        var syncDataRaw = e.data;
		var syncDataParts = syncDataRaw.split('::');
		
		var syncVar = syncDataParts[0];
		var syncValue = syncDataParts[1];

        if(syncVar=="broadcastLLTS"){

            var broadcastLowLatencyChunk_array = syncValue.split('||');
			//sendMessage("broadcastLLTS::"+cTimeStamp+"||LIVE||KEY||stream"+secondToBuildSegment+"_"+allSecondTSSegments_counter+".ts||");
			var broadcastLowLatencyChunk_timestamp = broadcastLowLatencyChunk_array[0]*1;
			var broadcastLowLatencyChunk_eventType = broadcastLowLatencyChunk_array[1];
			var broadcastLowLatencyChunk_chunkType = broadcastLowLatencyChunk_array[2];
			var broadcastLowLatencyChunk_chunkSecond = broadcastLowLatencyChunk_array[3]*1;
			var broadcastLowLatencyChunk_chunkSubSegment = broadcastLowLatencyChunk_array[4]*1;

            /*
            if(this._firstChunkReceived){

                var secuenceIndex = (broadcastLowLatencyChunk_chunkSecond * 10) + broadcastLowLatencyChunk_chunkSubSegment;
                this._currentChunckIndex = secuenceIndex;

                this._firstChunkReceived = false;

            }
            */
			

			if(broadcastLowLatencyChunk_eventType=="LIVE"){

				if(broadcastLowLatencyChunk_chunkType=="KEY"){

                    if(broadcastLowLatencyChunk_chunkSubSegment==0){

                        if(!this._keyVideoReceived){
                            this._keyVideoReceived = true;

                            var secuenceIndex = (broadcastLowLatencyChunk_chunkSecond * 10) + broadcastLowLatencyChunk_chunkSubSegment;
                            this._currentChunckIndex = secuenceIndex;

                            //setTimeout(function(){ this._playbackManager(secuenceIndex); }, 400);
                            this._playbackManager(secuenceIndex);

                        }

                    }

                    

					var broadcastLowLatencyChunk_widthResolution = broadcastLowLatencyChunk_array[5]*1;
					var broadcastLowLatencyChunk_heightResolution = broadcastLowLatencyChunk_array[6]*1;

                    /*
					if(broadcastLowLatencyChunk_heightResolution==480){
						document.getElementById("shareImg").setAttribute("src", "img/shareImageHRLiveV6_360p.png");
					}else if(broadcastLowLatencyChunk_heightResolution==450){
						document.getElementById("shareImg").setAttribute("src", "img/shareImageHRLiveV6_540p.png");
					}else if(broadcastLowLatencyChunk_heightResolution==720){
						document.getElementById("shareImg").setAttribute("src", "img/shareImageHRLiveV6_720p.png");
					}else if(broadcastLowLatencyChunk_heightResolution==1080){
						document.getElementById("shareImg").setAttribute("src", "img/shareImageHRLiveV6_1080p.png");
					}
                    */

					if(broadcastLowLatencyChunk_chunkSecond==0 && broadcastLowLatencyChunk_chunkSubSegment==0){
						
                        this._mediaChunksMap = new Map();
                        this._currentChunckIndex = 0;
                        //this._mediaChunksStatus = new Map();

                        /*
						playerMPEGTS.destroy();
						
						playerMPEGTS = mpegts.createPlayer({
            					type: 'mpegts',  // could also be mpegts, m2ts, flv
            					isLive: true,
            					url: 'wss://demo.wom6.com:56154'
        					});
        					playerMPEGTS.attachMediaElement(videoElement);
        					playerMPEGTS.load();

							//console.log("DESTROY PLAYER");
                        */

						/*
						//if(player!=null){

							//player.destroy();


							player.pause();
            				player.unload();
            				player.detachMediaElement();
            				player.destroy();

							//player = null;
							player = mpegts.createPlayer({
            					type: 'mpegts',  // could also be mpegts, m2ts, flv
            					isLive: true,
            					url: 'wss://demo.wom6.com:56154'
        					});
        					player.attachMediaElement(videoElement);
        					player.load();

							console.log("DESTROY PLAYER");

						//}
						*/
						
					}

                    if(this._keyVideoReceived){
					    //console.log("*KEY CHUNK RECEIVED: "+broadcastLowLatencyChunk_chunkSecond+"_"+broadcastLowLatencyChunk_chunkSubSegment);
                    }


				}else{

                    if(this._keyVideoReceived){
                        //console.log("CHUNK RECEIVED: "+broadcastLowLatencyChunk_chunkSecond+"_"+broadcastLowLatencyChunk_chunkSubSegment);
                    }
					

				}

				if(this._keyVideoReceived){

                    if(this._noDataCounter>=30){

                        /*
                        var secuenceIndexToSkip = (broadcastLowLatencyChunk_chunkSecond * 10) + broadcastLowLatencyChunk_chunkSubSegment;
                        this._skipChunks.set(secuenceIndexToSkip,true);
                        this._skipCounter--;
                        */

                        

                        
                        if(broadcastLowLatencyChunk_chunkSubSegment==0){

                            this._downloadFile(broadcastLowLatencyChunk_chunkSecond,broadcastLowLatencyChunk_chunkSubSegment);

                        }else{

                            console.log("STOP DOWNLOAD FOR: "+broadcastLowLatencyChunk_chunkSecond+""+broadcastLowLatencyChunk_chunkSubSegment);

                        }
                        

                    }else{
                        this._downloadFile(broadcastLowLatencyChunk_chunkSecond,broadcastLowLatencyChunk_chunkSubSegment);
                    }
                    

                }
				


			}

        }

        /*
        if (e.data instanceof ArrayBuffer) {
            this._dispatchArrayBuffer(e.data);
        } else if (e.data instanceof Blob) {
            let reader = new FileReader();
            reader.onload = () => {
                this._dispatchArrayBuffer(reader.result);
            };
            reader.readAsArrayBuffer(e.data);
        } else {
            this._status = LoaderStatus.kError;
            let info = {code: -1, msg: 'Unsupported WebSocket message type: ' + e.data.constructor.name};

            if (this._onError) {
                this._onError(LoaderErrors.EXCEPTION, info);
            } else {
                throw new RuntimeException(info.msg);
            }
        }
        */
    }

    _dispatchArrayBuffer(arraybuffer) {
        let chunk = arraybuffer;
        let byteStart = this._receivedLength;
        this._receivedLength += chunk.byteLength;

        if (this._onDataArrival) {
            this._onDataArrival(chunk, byteStart, this._receivedLength);
        }
    }

    _onWebSocketError(e) {
        this._status = LoaderStatus.kError;

        let info = {
            code: e.code,
            msg: e.message
        };

        if (this._onError) {
            this._onError(LoaderErrors.EXCEPTION, info);
        } else {
            throw new RuntimeException(info.msg);
        }
    }

}

export default WebSocketLoader;